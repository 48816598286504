
/*
let url = URL ( string: "\(Config.baseUrl())/api/scanner/history-event/\(qrCode)")!
let apiRequest = APIRequest( url: url )
let result: GetFrameHistoryResponse = try await  apiRequest.getAsync()
*/

import { useEffect, useState } from "react";
import { Card } from 'primereact/card';
import Config from '../config';
import useFetch from '../hooks/use-fetch';
import { GetFrameHistoryResponse } from './models/get-frame-history-response.dto';
import FrameEntryModel from './models/frame-entry.model';
import { Timeline } from 'primereact/timeline';
import TimeLineDateTime from './time-line-date-time';
import { Divider } from 'primereact/divider';
import FrameHistoryEntryTimeLineView from './frame-history-entry-timeline-view';
import { ProgressSpinner } from "primereact/progressspinner";

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/

interface IFrameHistoryTimeLine{
      frame: FrameEntryModel
}

//(props: IDailyActivityReportProps) => {
const FrameHistoryTimeLine = ( props: IFrameHistoryTimeLine) => { 

      const [frameSerial, setFrameSerial] = useState('');
      const [frameType, setFrameType] = useState('');
      const url = `${Config.baseUrl}/api/scanner/history-event/${props.frame.serialId}`;

      const [ query, setQuery ] = useState('');
      const { data, loading, error } = useFetch<GetFrameHistoryResponse>(url ?? "", [], {});


      return (
            
            <Card title="Frame History" subTitle={props.frame.serialId}  className="w-max">
                       <Divider/>
                   {error && <p>An error occurred searching for the frame.</p>}


                    {loading && <div> <ProgressSpinner /> Retrieving history...</div>}
                    
                  { data && data.historyEvents && data.historyEvents.length > 0   &&

      
                        <Timeline value={data.historyEvents}  opposite={TimeLineDateTime}  content={FrameHistoryEntryTimeLineView} />

                  }
            </Card>

      )

}

/*          { data && data.historyEvents && data.historyEvents.length > 0 && data.historyEvents.map((stationRow: FrameHistoryEntry) => {
                        return (
      
                              <FrameHistoryEntryView entry={stationRow}></FrameHistoryEntryView>

                        )
                  })}
                  */

export default FrameHistoryTimeLine;