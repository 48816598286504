import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useParams } from "react-router-dom";
import { FrameProduct } from "../../models/frame-product";
import PLACEHOLDER_IMAGE from './PLACEHOLDER_IMAGE.jpg';
import GridRow from '../station-productivity-report/grid-row';
import GridHeaderRow from "../station-productivity-report/grid-header";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import GridTotalsRow from "../station-productivity-report/grid-totals-row";
import { DateTime } from "luxon";
import DatePicker from "../../date-pickers/date-picker";



interface IStationFrameCompletion {
      stationId: number;
      stationName: string;
      frameStyles: IFrameStyles[];
}

interface IProduct {
   
      productId: string;
      createdTimestamp: Date;
      createdBy: string;
      lastUpdated: Date;
      lastUpdatedBy: number | null;
      deletedBy: string | null;
      sku: string;
      skuInternal: string;
      modelName: string;
      modelYear: string;
      modelSize: string;
      manufacturer: string;
      description: string | null;
      infoJsonB: object;
      frameProfileId: string | null;
      barCode: string;
      frameStyle: IFrameStyles;
      frameStyleId: string;
    //  productCategory: ProductCategories;
   //   frameProfile: FrameProfiles;
      openBoxBarCode: string | null;
      openBoxSku: string | null;

      framesCompleted: number; // used by a report
}

interface IFrameStyles {
      frameStyleId: string;
      frameStyle: string;
      frameProfile: string;
      frameProfileId: string;
   //   products: Products[];
     // productMouldings: ProductMouldings[];
     // limitedFrameStyles: LimitedFrameStyles;
     // frameCategory: FrameCategory;      
      frameCategoryId: string;  
      sideChipImage: string | null;
      frontChipImage: string | null;
      sideCornerImage: string | null;
      frontCornerImage: string | null;
      perspectiveCornerImage: string | null; 

      frames?: IProduct[]; //used by a report
}
        
interface IShowSideBarMenuProps {
      isMenuVisible: boolean,
      showSideBarMenu: (show: boolean) => void;
}


const FramesBoxedReport = ( props: IShowSideBarMenuProps) => {
      const [date, setDate] = useState<DateTime | null>(null);
      const params = useParams();

      const url = date && `${Config.baseUrl}/api/admin/reports/boxing/frames-boxed/?date=${date.toFormat('yyyy-MM-dd') }`;
      const { data, loading, error } = useFetch<IStationFrameCompletion[]>(url ?? "", [], {});
      let curFrameStyle = "";

      const frameSizes = [ "32", "43", "50", "55", "65", "75", "85" ];

      /*
      useEffect(() => {

            // if a ULRL params are included, assume the report is for posting to slack so hide the menu and date selector to keep the posting minimal
            if (window.location.search) {
                  const urlParams = new URLSearchParams(window.location.search);
                  let date = urlParams.get('date')
                  if (date) {
                        const newDate =  DateTime.fromISO( date );
                        setDate( newDate );
                  }
                  props.showSideBarMenu(false);

                  setTimeout(() => {
                        props.showSideBarMenu(true);
                  }, 3000);
            }

      }, []);


      useEffect(() => {
            // sends an event to puppeteer when the page is fully loaded and data is displayed
            if ( loading === false && data && document.readyState === 'complete' ) {
                  console.log( 'data - app ready')
                  window.dispatchEvent(new Event("react-app-ready"));
            }

      }, [loading, data]);

      */
     
      function setNewDate( dateString: string ) {
            const newDate = DateTime.fromISO( dateString );
            setDate( newDate )
      }
//              
      function convertoFrameProduct( frame: any) {
     
            let product = {
                  modelSize: frame.modelSize,
                  image: frame.frameStyle.perspectiveCornerImage != null ? frame.frameStyle.perspectiveCornerImage :  frame.frameStyle.sideChipImage ?  frame.frameStyle.sideChipImage : "NOT_FOUND",
                  barCode: "",
                  modelName: frame.modelName,
                  modelYear: frame.modelYear,
            }
            return new FrameProduct( product);
      }

      function camelCaseToWords(s: string) {
            const result = s.replace(/([A-Z])/g, ' $1');
            return result.charAt(0).toUpperCase() + result.slice(1);
      }

          
      return (

            <div className='report' >
                
                <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>
                                    <DatePicker startDateCallback={(date) => setNewDate( date ) } />
                              </div>
           
                  </div>
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Frames Boxed</div> 
                              <div className="report-date-title">{date?.toFormat( "MMM-dd-yyyy")}</div>
                        </div>

                        <div className="flex flex-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                          
                              {data && data.length === 0 &&
                                       <div>No frames were boxed on this date.</div>
                              }

                              {data && data.length > 0  && data.map((station: IStationFrameCompletion) => {
                                    return (

                                          <div key={station.stationId}  className="station-productivity-report flex flex-column flex-wrap justify-content-start  align-items-start align-content-start  frame-consumption-entry" >

                                                <div className="frame-grid-station-title"> { camelCaseToWords(station.stationName)}</div>

                                                {/*
                                                   NEED TO ACCOUNT FOR YEAR AS WELL - could use an inventory like grid ??
                                                            AS well alloy versus premiere
                                                                                    2020                              2021
                                                                        "32" | "43", "50", "55", "65", "75" |  "43", "50", "55", "65", "75" | [85]
                                                FRAMESTYLE IMAGE          4      -     9      9     1    -     23

                                                */}
                                                <GridHeaderRow/>
                                                {station.frameStyles  && station.frameStyles.map((frameStyle: IFrameStyles) => {
                                                 
                                                      return (

                                                             <div className="flex flex-row  frame-consumption-entry-image" key={station.stationId + ':' + frameStyle.frameStyleId}  >

                                                                  <div className="flex align-self-start frame-header-cell-framestyle">
                                                                        <div className="flex flex-row frame-style-color frame-cell-framestyle" >
                                                                              <img src={ `/img/frames/${frameStyle.perspectiveCornerImage ? frameStyle.perspectiveCornerImage : frameStyle.sideChipImage  ? frameStyle.sideChipImage  : 'unknown'}.jpg` } alt="frame"  
                                                                                    style={{maxWidth: "70px"}}/>
                                                                             {frameStyle.frameProfile === "Scoop" || frameStyle.frameProfile === "Prismatic" ? frameStyle.frameProfile : "" } {frameStyle.frameStyle}
                                                                        </div>
                                                                  </div>

                                                                  <GridRow  frames={frameStyle.frames ?? []} />
                                                                  
                                                            </div>  

                                                      )
                                                    
                                                })}

                                                 <div className="flex flex-row  frame-consumption-entry-image totals-row" key={station.stationId + ':totalrow'}  style={{backgroundColor:"darkblue", color:"white"}} >
                                                      <div className="flex align-self-start frame-header-cell-framestyle">
                                                            <div className="flex flex-row frame-style-color frame-cell-framestyle" style={{border:"none",backgroundColor:"black", color:"white"}} >                                                      
                                                                  Totals: 
                                                            </div>
                                                      </div>
                                                      <GridTotalsRow frames= {station.frameStyles.flatMap( f=> f.frames! ) } />
                                                </div>       
                                          </div>
                                    )
                              })}                             
      
                        </div>

                     
                  </Card>
                
            </div>
      )
}

export default FramesBoxedReport;