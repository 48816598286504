import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import Config from "../../config";
import { BarSeriesOption, EChartsOption, SeriesOption } from "echarts/types/dist/echarts";
import { ReactECharts } from "../../echarts/react-echarts";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import { useLocation } from 'react-router-dom';
import { DurationUtils } from "../../utils/duration-utils";
import { Duration } from "../../models/duration";
import { Panel } from "primereact/panel";
import { DateTime } from "luxon";
import DatePicker from "../../date-pickers/date-picker";

interface IUserPerformanceResult {
      stationId: number,
      stationName: string,
      stationFrameType: string;
      entries: IUserPerformance[]
}

interface IUserPerformance {
      firstname: string
      lastname: string
      userId: string;
      totalFramesCompleted: number,
      totalFrameTime: Duration;
      totalStationTime: Duration;
      avgTimePerFrame: Duration;
      avgTimePerFrameStation: Duration;
      stationId: string;
      stationName: string;
      stationFrameType: string;
      date: string;

}


interface IUserStationRow {
      userName: string
      stationName: string
      stationFrameType: string;
      percentage: number;
      date: string;

}

interface IPerformanceChartPerStation {
      stationId: number;
      stationName: string;
      stationFrameType: string;
      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}

interface ISeriesEntry {
      name: string;//'Direct',
      type: string; //'bar',
      stack: string; //'total',
      label: {
            show: true,
            formatter: any //()// {
            //          return "10% (30 frames total) \n xxxxxxxxxxxx"
            //      }
      },
      emphasis: {
            focus: string //'series'
      },
      data: number[] //[320]
}
const UserProductivityReport = () => {
      const location = useLocation()
      const queryParameters = new URLSearchParams(location.search)
      const [date, setDate] = useState<DateTime | null>(null);
      const [eBarChartOptions, setEBarChartOptions] = useState<IPerformanceChartPerStation[]>()
      //const params = useParams();
      // let x = `${Config.baseUrl}/api/admin/reports/production/performance/per-user?date=${new Date( `${date} EDT`).getFullYear().toString()}-${ ( new Date( `${date} EDT`).getMonth()  + 1).toString().padStart(2, '0')}-${ new Date( `${date} EDT`).toString().padStart(2, '0')}`;

      const url = date && `${Config.baseUrl}/api/admin/reports/production/performance/per-user?date=${date.toFormat('yyyy-MM-dd')}`;
      const { data, loading, error } = useFetch<IUserPerformanceResult[]>(url ?? "", [], {});

      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }

      useEffect(() => {

            function getData() {
                  if (!data) {
                        return
                  }
                  if (data.length === 0) {
                        return
                  }
                  let charts: IPerformanceChartPerStation[] = [];

                  for (let station of data) {
                        //   for (let entry of station.entries ) {
                        //New value = (value – min) / (max – min) * 100
                        /*
                  let option = {
                        legend: {},
                        tooltip: {},
                        dataset: {
                              dimensions: [] as string[], //['person', 'today'],
                              source: [] as any[],
                                    // { person: 'Juan', today: 30 },
                                    // { person: 'Maria', today: 76 },
                                    // { person: 'Tio', today: 100 }
                                    // ]
                        },
                        xAxis: { type: 'category' },
                        yAxis: {},
                        // Declare several bar series, each will be mapped
                        // to a column of dataset.source by default.
                        series: [
                              {
                                    type: 'bar',
                                    colorBy: 'data',
                                    color: ['#dd6b66', '#759aa0', '#e69d87', '#8dc1a9'],
                                    showBackground: true
                              }
                        ]
                  } as any; */

                        const option = {
                              tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                          // Use axis to trigger tooltip
                                          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                    }
                              },
                              legend: {},
                              grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    top: '10%',
                                    containLabel: true,
                                    height: "200px",
                              },
                              xAxis: {
                                    type: 'value'
                              },
                              yAxis: {
                                    type: 'category',
                                    data: ['Today']
                              },
                              series: [] as ISeriesEntry[]
                        } as EChartsOption;



                        let stationData = data.find(e => e.stationId === station.stationId);
                        //https://www.quora.com/What-is-meant-by-normalization-of-any-number-to-100
                        //const max = stationData!.entries.map(e => e.totalFramesCompleted ?? 0).reduce((a, b) => Math.max(a, b), 0);
                        //const min = stationData!.entries.map(e => e.totalFramesCompleted ?? 0).reduce((a, b) => Math.min(a, b));
                        const totalFramesCompletedAtStation = stationData!.entries.map(e => e.totalFramesCompleted ?? 0).reduce((acc, value) => { return acc + value; }, 0);

                        option.series = [];

                        if (data && data.length > 0) {

                              for (let r of station.entries) {

                                    let row = {} as BarSeriesOption;
                                    row.name = r.firstname + " " + r.lastname;
                                    row.type = 'bar';
                                    row.stack = 'total';
                                    row.label = {
                                          show: true,
                                          formatter: () => {
                                                return `${Math.round(r.totalFramesCompleted / totalFramesCompletedAtStation * 100)}%  (${r.totalFramesCompleted}) \n ${r.firstname} \n ${r.lastname}`;
                                          }
                                    };

                                    row.emphasis = {
                                          focus: 'series'
                                    };

                                    row.data = [r.totalFramesCompleted];
                                    option.series.push(row)
                              }
                        }
                        //                                    row.label =percentage = (r.totalFramesCompleted - min) / (max - min) * 100;

                        charts.push({ stationName: station.stationName, stationId: station.stationId, stationFrameType: station.stationFrameType, eBarChartOptions: option, rawData: station.entries });
                  }

                  setEBarChartOptions(charts);
            }

            getData()

      }, [data]);

      function setNewDate( dateString: string ) {
            const newDate = DateTime.fromISO( dateString );
            setDate( newDate );
      }

      return (
            <div className='report' style={{ maxWidth: "800px" }}>
                  <div className="calendar-selector">
                        <div className='flex flex-row pl-5'>
                              <div className="text-2xl text-white pr-5 align-self-center">Select Date: </div>
                              <DatePicker startDateCallback={(date) => setNewDate( date ) } />
                        </div>
                  </div>


                  <Panel className={"body-panel"}>
                        <div className="report-title-wrapper">
                              <div className="report-title">User Productivity</div>
                              <div className="report-date-title">{date?.toFormat( "MMM-dd-yyyy")}</div>
                        </div>


                        {error && <div>An error occurred loading the report.</div>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}

                        {data && data.length === 0 &&
                              <div>No frames were completed on this day</div>
                        }

                        {eBarChartOptions && eBarChartOptions.map((entry: IPerformanceChartPerStation) => {
                              return (


                                    <Card className="nested-card" style={{ display: "flex" }} >

                                          <h2>{entry.stationFrameType} {entry.stationName}</h2>

                                          <ReactECharts style={{ height: "200px" }} option={entry.eBarChartOptions} />

                                          <div className="stats" style={{ display: "flex", fontSize: "12px" }}>

                                                <table>

                                                      <tr style={{backgroundColor: "lightblue", color: "#595959"}}>
                                                            <th style={{ wordWrap: "break-word" }}>Name</th>
                                                            <th style={{ wordWrap: "break-word" }}>Frames Completed</th>
                                                            <th style={{ wordWrap: "break-word" }}>Avg Time per Frame</th>
                                                            <th style={{ wordWrap: "break-word" }}>Frame Time</th>
                                                            <th style={{ wordWrap: "break-word" }}>Avg Time per Frame (Station)</th>
                                                            <th style={{ wordWrap: "break-word" }}>Station Time</th>
                                                      </tr>



                                                      {entry.rawData.map((e: IUserPerformance) => {
                                                            return (

                                                                  <tr>
                                                                        <td style={{ wordWrap: "break-word" }}>{e.firstname} {e.lastname} </td>
                                                                        <td align='right'>{e.totalFramesCompleted}</td>
                                                                        <td align='right'>{DurationUtils.formatDuration(e.avgTimePerFrame)}</td>
                                                                        <td align='right'>{DurationUtils.formatDuration(e.totalFrameTime)}</td>
                                                                        <td align='right'>{DurationUtils.formatDuration(e.avgTimePerFrameStation)}</td>
                                                                        <td align='right'>{DurationUtils.formatDuration(e.totalStationTime)}</td>
                                                                  </tr>


                                                            )
                                                      })}


                                                </table>
                                          </div>

                                    </Card>

                              )
                        })}



                  </Panel>


            </div>
      )
}

export default UserProductivityReport;
