
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import './date-range-selector.css'
import { DateTime } from "luxon";
import { OverlayPanel } from "primereact/overlaypanel";
import { start } from "repl";
import NextPreviousDayButtons from "../reports/components/next-previous-day-buttons";

interface IDatePickerProps {
      startDateCallback: (dateString: string) => void;
}

export default function DatePicker(props: IDatePickerProps) {

      const [startDate, setStartDate] = useState<DateTime | null>();
      const [endDate, setEndDate] = useState<DateTime | null>(null);
      const [startDateRange, setStartDateRange] = useState<Date | null>(null);
      const [optionSelected, setOptionSelected] = useState<string | null>( null);
      const firstUpdate = useRef(true);
      const overLayPanel = useRef(null);
      
      useEffect(() => {

            // if a ULRL params are included load it as the start date (on page load)
            if ( window.location.search ){
                  const urlParams = new URLSearchParams( window.location.search);
                  let date = urlParams.get('date')
                  if (date) {
                        const newDate =  DateTime.fromISO( date );
                        setStartDate( newDate );
                  } else {
                        // default to 'today' if no date is preset in the url
                        setStartDate( DateTime.now() )
                        setDateInUrlParams( DateTime.now() )
                  }
            } else {
                  setStartDate( DateTime.now() )
                  setDateInUrlParams( DateTime.now() )
            }

      }, []);

      useEffect(() => {
            if (startDate ) {
                  props.startDateCallback( startDate!.toFormat( 'yyyy-MM-dd') );
            }
      }, [startDate]);

      function calculateDatesFromCalendarStart( date: Date) {
            setSelectedRangeOption('startDate', true);
            setStartDateRange( date );
            //calendar uses a JS date so need to convert to DateTime
            let dateWithTimeZone = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour: 0, minute: 0, second: 0, millisecond: 1 }, { zone:  'America/Los_Angeles' })
            setStartDate( dateWithTimeZone );
            (overLayPanel.current as unknown as OverlayPanel).hide();
      }
      ///////////
      function setDateInUrlParams( newDate: DateTime ){
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('date', newDate.toFormat('yyyy-MM-dd'));
            window.history.replaceState("", "", window.location.pathname + "?date=" + newDate.toFormat('yyyy-MM-dd'));
      }

      function onChangeDate(days: number) {
            if (startDate) {
                  let newDate = startDate; 
                  if ( days > 0 ) {
                        newDate = startDate.plus({days: 1})
                  } else {
                        newDate = startDate.minus({days: 1})
                  }
                  setStartDate(newDate);
                  setDateInUrlParams(newDate);
            }
      }

      const [quickRangeOption, setQuicRangeOption] = useState<number | null>(null);
      const quickRangeOptions = [
            { name: "30 Days Ago", value: 1},
            { name: 'Start of This Week', value: 2 },
            { name: 'Start of Last Week', value: 3 },           
            { name: 'Start of This Month', value: 4 },
            { name: 'Start of Last Month', value: 5 },
            { name: '3 Months Ago', value: 6 },
            { name: '6 Months Ago', value: 7 },
            { name: 'Start of year', value: 8 },
            { name: 'Start of last Year', value: 9 },
      ];


      
      function calculateQuickRangeOptions( selectedOption: number ) {

            setQuicRangeOption( selectedOption );
            setSelectedRangeOption('quickRange', true);
            const now = DateTime.now();
            let startDate: DateTime = DateTime.now();
   
            if ( selectedOption === 1 ) { // last 30
                  startDate = now.minus({days: 30} );
            } else if ( selectedOption === 2 ) { //this week
                  startDate = now.startOf('week');
            } else if ( selectedOption === 3 ) { // last week
                  startDate = now.minus({weeks: 1}).startOf('week');
            } else if ( selectedOption === 4 ) { // this month
                  startDate = now.startOf('month' );
            } else if ( selectedOption === 5 ) { // last month
                  startDate = now.minus({months: 1}).startOf('month');
            } else if ( selectedOption === 6 ) { // last 3 months
                  startDate = now.minus({months: 3}).startOf('month');
            } else if ( selectedOption === 7 ) { // last 6 months
                  startDate = now.minus({months: 6}).startOf('month');
            } else if ( selectedOption === 8 ) { //this year
                  startDate = now.startOf('year' );
            } else if ( selectedOption === 9) { //last year
                  startDate = now.minus({years: 1}).startOf('year');
            }

            setStartDate( startDate );
            (overLayPanel.current as unknown as OverlayPanel).hide();
      }
   
      function setSelectedRangeOption( option: string, maintainSelection: boolean = false ) {
            if ( maintainSelection ) {
                  setOptionSelected( option )
            } else {
                  if ( optionSelected === option ) {
                        setOptionSelected(null)
                  } else {
                        setOptionSelected( option )
                  }
            }
      }


      return (
            <>
            <Button type="button" icon="pi pi-calendar" label="Select Date" onClick={(e) => (overLayPanel!.current! as any).toggle(e)} />
            { startDate && <NextPreviousDayButtons onChangeDate={onChangeDate} />}
            <OverlayPanel ref={overLayPanel} showCloseIcon>
             
                  <div className="card flex flex-column align-items-start bg-white">
                        <div className="date-selector-row"> {startDate && startDate && <>{startDate.toISODate()}</>} </div>
                        
                        <div className="date-selector-row">
                              <>
                              <div className="selector" onClick={()=> { setSelectedRangeOption('startDate'); calculateDatesFromCalendarStart( startDate!.toJSDate() ) } }> 
                                    <div className={ optionSelected === 'startDate' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                              </div>
                              <div className="title">Date</div>
                              <div className="options">
                                    <Calendar value={startDateRange} onChange={(e) => { e.value && calculateDatesFromCalendarStart((e.value as Date)); }} dateFormat="mm/dd/yy" showIcon showButtonBar touchUI />
                              </div>
                              </>
                        </div>
                        <div className="date-selector-row">
                              <div className="selector" onClick={()=> { setSelectedRangeOption('quickRange'); if ( quickRangeOption ) { calculateQuickRangeOptions(quickRangeOption); }; }}> 
                                    <div className={ optionSelected === 'quickRange' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                              </div>    
                              <div className="title">Quick Date </div>   
                              <div className="options">
                                    <SelectButton value={quickRangeOption} onChange={(e) => calculateQuickRangeOptions(e.value)} optionLabel="name" options={quickRangeOptions} />
                              </div>                 
                        </div>

                  </div>

            </OverlayPanel>
            </>
      )

}