
import React, { useEffect, useRef, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import './date-range-selector.css'
import { DateTime } from "luxon";
import { OverlayPanel } from "primereact/overlaypanel";
import { DateUtils } from "../utils/date-utils";

interface IDateRangePickerProps {
      startDateCallback: (dateString: string) => void;
      endDateCallback: (dateString: string) => void;

      //set a default range for when first opening the report, otherwise default 1st of month to today
      defaultStartDate?: DateTime;
      defaultEndDate?: DateTime;
}

interface IMonth{
      month: string;
      value: number;
}

export default function DateRangePicker(props: IDateRangePickerProps) {

      const [startDate, setStartDate] = useState<DateTime | null>(null);
      const [endDate, setEndDate] = useState<DateTime | null>(null);

      const [optionSelected, setOptionSelected] = useState<string | null>( null);

      const [startDateRange, setStartDateRange] = useState<Date | null>(null);
      const [endDateRange, setEndDateRange] = useState<Date | null>(null);

      const [monthSingle, setMonthSingle] = useState<number | null>(null);
      const [yearForMonthSingle, setYearForMonthSingle] = useState<number | null>(new Date().getFullYear());

      const [startMonthRange, setStartMonthRange] = useState<number | null>(null);
      const [endMonthRange, setEndMonthRange] = useState<number | null>(null);
      const [yearForMonthRange, setYearForMonthRange] = useState<number | null>(new Date().getFullYear());

      const [yearSingle, setYearSingle] = useState<number | null>(new Date().getFullYear());

      const overLayPanel = useRef(null);

      useEffect(() => {

            // if a ULRL params are included load it as the start date (on page load)
            if ( window.location.search ){
                  const urlParams = new URLSearchParams( window.location.search);
                  const startdate = urlParams.get('startdate')
                  if (startdate) {
                        const newDate =  DateTime.fromISO( startdate );
                        setStartDate( newDate );
                  } 
                  const enddate = urlParams.get('enddate')
                  if (enddate) {
                        const newDate =  DateTime.fromISO( enddate );
                        setEndDate( newDate );
                  } 
                  
                  else {
                        // default to 'today' if no date is preset in the url
                        if ( !props.defaultStartDate || !props.defaultEndDate ) {
                              setStartDate( DateTime.now().startOf( 'month') )
                              setEndDate( DateTime.now() )
                              setDateInUrlParams( DateTime.now().startOf( 'month'), DateTime.now() );
                        } else {
                              setDateInUrlParams( props.defaultStartDate, props.defaultEndDate );                              
                        }
                  }
            } else {
                  if ( !props.defaultStartDate || !props.defaultEndDate ) {
                        setStartDate( DateTime.now().startOf( 'month') )
                        setEndDate( DateTime.now() )
                        setDateInUrlParams( DateTime.now().startOf( 'month'), DateTime.now() );
                  } else {
                        setStartDate( props.defaultStartDate )
                        setEndDate( props.defaultEndDate )
                        setDateInUrlParams( props.defaultStartDate, props.defaultEndDate );                          
                  }                  
            }

      }, []);

      
      useEffect(() => {
            if (startDate ) {
                  props.startDateCallback( startDate!.toFormat( 'yyyy-MM-dd') );
                  setDateInUrlParams( startDate, endDate)
            }
      }, [startDate]);


      useEffect(() => {
            if (endDate ) {
                  props.endDateCallback( endDate!.toFormat( 'yyyy-MM-dd') );
                  setDateInUrlParams( startDate, endDate)
            }
      }, [endDate]);


      function setDateInUrlParams( startDate: DateTime | null, endDate: DateTime | null ){
            const urlParams = new URLSearchParams(window.location.search);
            if ( startDate ) {
                  urlParams.set('startDate', startDate.toFormat('yyyy-MM-dd'));
            }
            if ( endDate ) {
                  urlParams.set('endDate', endDate.toFormat('yyyy-MM-dd'));
            }
            if ( startDate && endDate ) {
                  window.history.replaceState("", "", window.location.pathname + "?startdate=" + startDate.toFormat('yyyy-MM-dd') + "&enddate=" + endDate?.toFormat('yyyy-MM-dd'));
            }
      }


      const months: IMonth[] = [
            { month: "January", value: 1 },
            { month: "February", value: 2 },
            { month: "March", value: 3 },
            { month: "April", value: 4 },
            { month: "May", value: 5 },
            { month: "June", value: 6 },
            { month: "July", value: 7 },
            { month: "August", value: 8 },
            { month: "September", value: 9 },
            { month: "October", value: 10 },
            { month: "November", value: 11 },
            { month: "December", value: 12 },
      ]
      const [quickRangeOption, setQuicRangeOption] = useState<number | null>(null);
      const quickRangeOptions = [
            { name: "Last 30 days", value: 1},
            { name: 'This Week', value: 2 },
            { name: 'Last Week', value: 3 },           
            { name: 'This Month', value: 4 },
            { name: 'Last Month', value: 5 },
            { name: 'Last 3 Months', value: 6 },
            { name: 'Last 6 Months', value: 7 },
            { name: 'This Year', value: 8 },
            { name: 'Last Year', value: 9 },
      ];

      const [quarter, setQuarter] = useState<number | null>(null);
      const [quarterYear, setQuarterYear] = useState<number | null>(new Date().getFullYear());
      const quarterOptions = [
            { name: '1st', value: 1 },
            { name: '2nd', value: 2 },
            { name: '3rd', value: 3 },
            { name: '4th', value: 4 },
      ];

      const [quarters, setQuarters] = useState<number | null>(null);
      const [quartersYear, setQuartersYear] = useState<number | null>(new Date().getFullYear());
      const quartersOptions = [
            { name: '1st & 2nd', value: 1 },
            { name: '2nd & 3rd', value: 2 },
            { name: '3rd & 4th', value: 3 },
      ];


      const years = [
            {year: "2020", value: 2020},
            {year: "2021", value: 2021},
            {year: "2022", value: 2022},
            {year: "2023", value: 2023},
            {year: "2024", value: 2024},
            {year: "2025", value: 2025},
            {year: "2026", value: 2026},
            {year: "2027", value: 2027},
            {year: "2028", value: 2028},
            {year: "2029", value: 2029},
            {year: "2030", value: 2030},
            {year: "2031", value: 2031},
            {year: "2032", value: 2032},
      ]
      
      function calculateDatesFromCalendarStart( date: Date) {
            setSelectedRangeOption('dateRange', true);
            setStartDateRange( date );
            //calendar uses a JS date so need to convert to DateTime
            let dateWithTimeZone = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour: 0, minute: 0, second: 0, millisecond: 1 }, { zone:  'America/Los_Angeles' })
            setStartDate( dateWithTimeZone );

      }

      function calculateDatesFromCalendarEnd( date: Date) {
            setSelectedRangeOption('dateRange', true);
            setEndDateRange( date );
            let dateWithTimeZone = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour: 23, minute: 59, second: 59, millisecond: 1 }, { zone:  'America/Los_Angeles' })
            setEndDate( dateWithTimeZone );
      }

      
      useEffect(() => {
            if (monthSingle ) {
                  calculateDatesFromMonthSingle( monthSingle! );
            }
      }, [yearForMonthSingle]);

      function calculateDatesFromMonthSingle( month: number ){
           setMonthSingle( month )
           setSelectedRangeOption('monthSingle', true);
           const dt = DateTime.fromObject({month: month, year: yearForMonthSingle! }, { zone: 'America/Los_Angeles'});
           const startDate = dt.startOf('month');
           const endDate = dt.endOf('month');
           setStartEndDate( startDate, endDate );
    
      }

      useEffect(() => {
            if (monthSingle ) {
                  calculateDatesFromMonthSingle( monthSingle! );
            }
      }, [yearForMonthSingle]);

      function calculateDatesFromMonthRangeStart( month: number ){

            setStartMonthRange( month );            
            setSelectedRangeOption('monthRange', true);      
            const dt = DateTime.fromObject({month: month, year: yearForMonthRange! }, { zone: 'America/Los_Angeles'});
            const startDate = dt.startOf('month')
            setStartDate( startDate );
       }

       function calculateDatesFromMonthRangeEnd( month: number ){
            setEndMonthRange( month );     
            setSelectedRangeOption('monthRange', true);     
            const dt = DateTime.fromObject({month: month, year: yearForMonthRange! }, { zone: 'America/Los_Angeles'});
            const endDate = dt.endOf('month');
            setEndDate( endDate );
            
       }

       useEffect(() => {
            if (startMonthRange && endMonthRange ) {
                  calculateDatesFromMonthRangeStart( startMonthRange! );
                  calculateDatesFromMonthRangeEnd( endMonthRange! );
            }
      }, [yearForMonthRange]);

      function calculateDatesForYear( year: number) {
            setYearSingle( year );
            setSelectedRangeOption('yearSingle')
            const dt = DateTime.fromObject({ year: year! }, { zone: 'America/Los_Angeles'});
            const startDate = dt.startOf('year');
            const endDate = dt.endOf('year');

            setStartEndDate( startDate, endDate );
               
            (overLayPanel.current as unknown as OverlayPanel).hide();
      }
      
      function calculateQuickRangeOptions( selectedOption: number ) {

            setQuicRangeOption( selectedOption );
            setSelectedRangeOption('quickRange', true);
            const now = DateTime.now();
            let startDate: DateTime = DateTime.now();
            let endDate: DateTime = DateTime.now();

            if ( selectedOption === 1 ) { // last 30
                  startDate = now.minus({days: 30} );
                  endDate = now;
            } else if ( selectedOption === 2 ) { //this week
                  startDate = now.startOf('week');
                  endDate = now;
            } else if ( selectedOption === 3 ) { // last week
                  startDate = now.minus({weeks: 1}).startOf('week');
                  endDate =  now.minus({weeks: 1}).endOf('week');
            } else if ( selectedOption === 4 ) { // this month
                  startDate = now.startOf('month' );
                  endDate = now;
            } else if ( selectedOption === 5 ) { // last month
                  startDate = now.minus({months: 1}).startOf('month');
                  endDate = now.minus({months: 1}).endOf('month');
            } else if ( selectedOption === 6 ) { // last 3 months
                  startDate = now.minus({months: 3}).startOf('month');
                  endDate = now;
            } else if ( selectedOption === 7 ) { // last 6 months
                  startDate = now.minus({months: 6}).startOf('month');
                  endDate = now;
            } else if ( selectedOption === 8 ) { //this year
                  startDate = now.startOf('year' );
                  endDate = now;
            } else if ( selectedOption === 9) { //last year
                  startDate = now.minus({years: 1}).startOf('year');
                  endDate = now.minus({years: 1}).endOf('year');
            }

            setStartEndDate( startDate, endDate );
   
            (overLayPanel.current as unknown as OverlayPanel).hide();
      }
      const calculateQuarter = ( quarterSelected: number ) =>  {

            setQuarter( quarterSelected );
            setSelectedRangeOption('quarter', true);

            let startOfYear: DateTime = DateTime.now().set({ year: quarterYear!}).startOf('year');

            let startDate: DateTime = DateTime.now();
            let endDate: DateTime = DateTime.now();

            if ( quarterSelected === 1 ) { // 1st
                  startDate = startOfYear;
                  endDate = startDate.endOf('quarter');
            } else if ( quarterSelected === 2 ) { //2nd
                  startDate = startOfYear.plus( { months: 3});
                  endDate = startDate.endOf('quarter');
            } else if ( quarterSelected === 3 ) { // 3rd
                  startDate = startOfYear.plus( { months: 6});
                  endDate = startDate.endOf('quarter');
            } else if ( quarterSelected === 4 ) { // 4th
                  startDate = startOfYear.plus( { months: 9});
                  endDate = startDate.endOf('quarter');
            } 

            setStartEndDate( startDate, endDate );

      }

      useEffect(() => {
            if ( quarter  ) {
                  calculateQuarter( quarter! );
            }
      }, [quarterYear]);

      function calculateQuarters( quartersSelected: number ) {
            setQuarters( quartersSelected );
            setSelectedRangeOption('quarters', true)
            let now: DateTime = DateTime.now().set({ year: quartersYear!})

            let startDate: DateTime = DateTime.now();
            let endDate: DateTime = DateTime.now();

            if ( quartersSelected === 1 ) { // 1st & 2nd
                  startDate = now.startOf('year');
                  endDate = startDate.plus({months:6}).minus({days:1});
            } else if ( quartersSelected === 2 ) { //2nd & 3rd
                  startDate = now.startOf('year').plus({months:3});
                  endDate = startDate.plus({months:6}).minus( {days: 1});
            } else if ( quartersSelected === 3 ) { // 3rd & 4th
                  startDate = now.startOf('year').plus({months:6});;
                  endDate = startDate.endOf('year');
            } 
            setStartEndDate( startDate, endDate );
      }

      useEffect(() => {
            if (quarters  ) {
                  calculateQuarters( quarters! );
            }
      }, [quartersYear]);

      function setStartEndDate( startDate: DateTime, endDate: DateTime ) {
            if ( startDate ) {
                  setStartDate( startDate );
            }
            if ( endDate ) {
                  setEndDate( endDate );
            }
           
      }

      function setSelectedRangeOption( option: string, maintainSelection: boolean = false ) {
            if ( maintainSelection ) {
                  setOptionSelected( option )
            } else {
                  if ( optionSelected === option ) {
                        setOptionSelected(null)
                  } else {
                        setOptionSelected( option )
                  }
            }

      }


      return (
            <>
            <Button type="button" icon="pi pi-calendar" label="Select Date Range" onClick={(e) => (overLayPanel!.current! as any).toggle(e)} />

            <OverlayPanel ref={overLayPanel} showCloseIcon>
             
            <div className="card flex flex-column align-items-start bg-white">
                  <div className="date-selector-row"> {startDate && startDate && <>{startDate.toISODate()}</>}  { (startDate || endDate ) &&  "/" } {endDate && <>{endDate?.toISODate()}</>} </div>
                   
                  <div className="date-selector-row">
                        <>
                        <div className="selector" onClick={()=> setSelectedRangeOption('dateRange')}> 
                              <div className={ optionSelected === 'dateRange' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>
                        <div className="title"> Date Range</div>
                        <div className="options">
                              <Calendar value={startDateRange} onChange={(e) => { e.value && calculateDatesFromCalendarStart((e.value as Date)); }} dateFormat="mm/dd/yy" showIcon showButtonBar touchUI />
                              <Calendar value={endDateRange} onChange={(e) => { e.value && calculateDatesFromCalendarEnd((e.value as Date)); }} dateFormat="mm/dd/yy" showIcon showButtonBar touchUI />
                        </div>
                        </>
                  </div>

                  <div className="date-selector-row">
                        <div className="selector" onClick={()=>{ setSelectedRangeOption('monthSingle'); if ( monthSingle ) { calculateDatesFromMonthSingle(monthSingle); }; }}> 
                              <div className={ optionSelected === 'monthSingle' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>
                        <div className="title"> Month</div>
                        <div className="options">
                              <Dropdown value={monthSingle} onChange={(e) => calculateDatesFromMonthSingle(e.value)} options={months} optionLabel="month" showClear placeholder="Month" className="" />
                        </div>
                        <div className="options">
                              <Dropdown value={yearForMonthSingle} onChange={(e) => setYearForMonthSingle( e.value)} options={years} optionLabel="year" placeholder="Year" className="" />
                        </div>
                  </div>

                  <div className="date-selector-row">
                        <div className="selector" onClick={()=> { 
                                                                  setSelectedRangeOption('monthRange'); 
                                                                  if ( startMonthRange ) calculateDatesFromMonthRangeStart(startMonthRange); 
                                                                  if ( endMonthRange ) calculateDatesFromMonthRangeEnd(endMonthRange); 
                        }}>

                              <div className={ optionSelected === 'monthRange' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>
                        <div className="title"> Month Range </div>
                        <div className="options">
                              <Dropdown value={startMonthRange} onChange={(e) => calculateDatesFromMonthRangeStart(e.value)} options={months} optionLabel="month" showClear placeholder="Start" className="" />
                              <Dropdown value={endMonthRange} onChange={(e) => calculateDatesFromMonthRangeEnd(e.value)} options={months} optionLabel="month" showClear placeholder="End" className="" />
                        </div>
                        <div className="title">Year</div>
                        <div className="options">
                              <Dropdown value={yearForMonthRange} onChange={(e) => { setYearForMonthRange(e.value); }}  options={years} optionLabel="year" placeholder="Year" className="" />
                        </div>
                  </div>
                  <div className="date-selector-row">
                        <div className="selector" onClick={()=> {setSelectedRangeOption('yearSingle'); if ( yearSingle ) { calculateDatesForYear(yearSingle); }; }}> 
                              <div className={ optionSelected === 'yearSingle' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>
                        <div className="title">Year</div>
                        <div className="options">
                              <Dropdown value={yearSingle} onChange={(e) => calculateDatesForYear(e.value)} options={years} optionLabel="year" placeholder="Year" className="" />
                        </div>
                  </div>
                  <div className="date-selector-row">
                        <div className="selector" onClick={()=> { setSelectedRangeOption('quarter'); if ( quarter ) { calculateQuarter(quarter); }; }}> 
                              <div className={ optionSelected === 'quarter' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>
                        <div className="title"> Quarter </div>
                        <div className="options">
                              <SelectButton value={quarter} onChange={(e) => calculateQuarter(e.value)} optionLabel="name" options={quarterOptions} />
                        </div>
                        <div className="title"> Year </div>
                        <div className="options">
                              <Dropdown value={quarterYear} onChange={(e) => setQuarterYear(e.value)} options={years} optionLabel="year" placeholder="Year" className="" />
                        </div>
                  </div>
                  <div className="date-selector-row">
                        <div className="selector" onClick={()=> { setSelectedRangeOption('quarters');  if ( quarters ) { calculateQuarters(quarters); }; }}> 
                              <div className={ optionSelected === 'quarters' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>                        
                        <div className="title"> Quarters </div>
                        <div className="options">
                              <SelectButton value={quarters} onChange={(e) => calculateQuarters(e.value)} optionLabel="name" options={quartersOptions} />
                        </div>
                        <div className="title"> Year </div>
                        <div className="options">
                              <Dropdown value={quartersYear} onChange={(e) => setQuartersYear(e.value)} options={years} optionLabel="year" showClear placeholder="Year" className="" />
                        </div>
                  </div>
                  <div className="date-selector-row">
                        <div className="selector" onClick={()=> { setSelectedRangeOption('quickRange'); if ( quickRangeOption ) { calculateQuickRangeOptions(quickRangeOption); }; }}> 
                              <div className={ optionSelected === 'quickRange' ? "pi pi-check-square" : "pi pi-circle" }> </div>
                        </div>    
                        <div className="title">Quick Range </div>   
                        <div className="options">
                              <SelectButton value={quickRangeOption} onChange={(e) => calculateQuickRangeOptions(e.value)} optionLabel="name" options={quickRangeOptions} />
                        </div>                 
                  </div>

            </div>

            </OverlayPanel>
            </>
      )

}