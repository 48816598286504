import { Link } from "react-router-dom";
import classNames from "classnames";
import { Fragment } from "react";
import './left-side-bar.css'
import { Button } from "primereact/button";
import { PanelMenu } from "primereact/panelmenu";

type LeftSidebarProps = {
      isLeftSidebarCollapsed: boolean;
      changeIsLeftSidebarCollapsed: (isLeftSidebarCollapsed: boolean) => void;
      menuItems: any
};

const LeftSidebar = ({
      isLeftSidebarCollapsed,
      changeIsLeftSidebarCollapsed,
      menuItems,
}: LeftSidebarProps) => {


      const sidebarClasses = classNames({
            sidenav: true,
            "sidenav-collapsed": isLeftSidebarCollapsed,
      });

      const closeSidenav = () => {
            changeIsLeftSidebarCollapsed(true);
      };

      const toggleCollapse = (): void => {
            changeIsLeftSidebarCollapsed(!isLeftSidebarCollapsed);
      };


      return (
            <div className={sidebarClasses} data-image="./login15.jpg">
                  {!isLeftSidebarCollapsed && (
                        <>
                              <div className="logo-container">
                                    <Button className="btn-close" icon="pi pi-bars" onClick={toggleCollapse} />
                                    <div className="logo-text">DecoNet</div>
                              </div>
                              <div className="sidenav-nav">
                                    <PanelMenu model={menuItems} />
                              </div>
                        </>
                  )}

                  {isLeftSidebarCollapsed && (
                        <>
                              <Button className="btn-close" style={{ position: "absolute", top: "5px", left: "5px", backgroundColor: "#3b3b3c1c" }} icon="pi pi-bars" onClick={toggleCollapse} />
                        </>
                  )}



            </div>
      );
};

export default LeftSidebar;

/*
{ menuItems.map((item:any) => (

      <li key={item.label} className="sidenav-nav-item">
            <Link className="sidenav-nav-link" to={item.routerLink}>

                  <i className={classNames({"sidenav-link-icon": true, [item.icon]: true, })}></i>

                  {!isLeftSidebarCollapsed && (
                        <span className="sidenav-link-text">{item.label}</span>
                  )}

            </Link>
      </li>

))}
      */